<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="Leads" :description="'Total '+pagination.totalRecords+' leads.'"></block-head>
          <block-content>
            <card no-padding>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header title="">
                    <card-tools>
                      <div class="form-inline flex-nowrap gx-3">
                        <div class="form-wrap w-150px">
                          <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                            <option value="">Bulk Action</option>
                            <option value="to_marketplace">To Marketplace</option>
                            <option value="delete">Delete</option>
                          </select>
                        </div>
                        <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">Apply</nk-button>
                          </span>
                          <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button=""
                                       class="search-toggle"
                                       v-on:click="searchBarActive=true"
                                       :class="{active: searchBarActive}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="btn-toolbar-sep"></li>
                          <li>
                            <drop-down
                                icon="ni-filter-alt"
                                size="lg"
                                direction="right"
                                :dot="( statusFilter !== '' || mandateFilter !== '' ? 'primary':'')"
                                title="Advisors Filter">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="mandate_filter">Mandate</label>
                                    <select class="form-control form-control-sm" id="mandate_filter" v-model="mandateFilter">
                                      <option value="">All</option>
                                      <option value="1">Signed</option>
                                      <option value="0">Not Signed</option>
                                    </select>
                                  </column>
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="status_filter">Status</label>
                                    <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                      <option value="">All</option>
                                      <option v-for="status in leadStatuses" :key="'ls'+status.id" :value="status.id">{{ status.status_text }}</option>
                                    </select>
                                  </column>
                                </row>
                              </template>
                              <template v-slot:footer>
                                <nk-button type="dark" dim v-on:click="getAdvisors(true)">Filter</nk-button>
                                <a class="clickable" href="javascript:;" v-on:click="[statusFilter=mandateFilter='',getAdvisors(true)]">Reset Filter</a>
                              </template>
                            </drop-down>
                          </li><!-- li -->
                          <li>
                            <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                              <ul class="link-check">
                                <li><span>Order</span></li>
                                <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">ASC</a></li>
                                <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">DESC</a></li>
                              </ul>
                            </drop-down>
                          </li><!-- li -->
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      placeholder="Search advisor"
                      :is-active="searchBarActive"
                      @do-search="searchAdvisors"
                      :search-term="searchFilter"
                      @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="loadingData">
                  <ion-spinner></ion-spinner>
                </div>
                <template v-else>
                  <div class="card-inner p-0">
                    <special-table>
                      <table-row is-heading>
                        <table-col has-check-box>
                          <b-check-box id="select_all" v-model="selectAll"></b-check-box>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Lead#</span>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Client</span>
                        </table-col>
                        <table-col break-point="mb">City</table-col>
                        <table-col break-point="md">Received</table-col>
                        <table-col break-point="lg">Phone</table-col>
                        <table-col break-point="lg">House</table-col>
                        <table-col class="nk-tb-col-tools"></table-col>
                      </table-row>

                      <table-row v-for="lead in leads" :key="'ld'+lead.id">
                        <table-col has-check-box>
                          <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                        </table-col>
                        <table-col>{{ lead.lead_number }}</table-col>
                        <table-col>
                          <user-small-card
                              :user-name="lead.first_name+' '+lead.last_name"
                              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
                        </table-col>
                        <table-col break-point="mb">
                          <span class="tb-lead">{{ lead.city }}</span>
                        </table-col>
                        <table-col break-point="md">{{ lead.received_time }}</table-col>
                        <table-col break-point="lg">{{ lead.phone_no }}</table-col>
                        <table-col break-point="lg">{{ lead.house_number }}</table-col>
                        <table-col class="nk-tb-col-tools">
                          <ul class="nk-tb-actions gx-1">
                            <table-action icon="ni-forward-arrow-fill"
                                          :to="{name: 'adminLeadDetails', params:{id:lead.id}}"
                                          v-tooltip="'Assign'"></table-action>
                            <table-action icon="ni-trash-fill" v-on:click="deleteRecords([lead], 'lead')" v-tooltip="'Delete'"></table-action>
                            <table-action icon="ni-cart-fill" v-on:click="_addToMarketplace([lead])" v-tooltip="'To marketplace'"></table-action>
                            <li>
                              <drop-down-list>
                                <list-item icon="ni-eye" text="View Details" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                                <list-item icon="ni-activity-round" text="Activities"></list-item>
                              </drop-down-list>
                            </li>
                          </ul>
                        </table-col>
                      </table-row>

                    </special-table>

                  </div>
                  <div class="card-inner" v-if="pagination.totalRecords > 10">
                    <pagination
                        v-model="pagination.page"
                        :records="pagination.totalRecords"
                        :per-page="10"
                        @paginate="paginationCallback"
                        theme="bootstrap4"
                        :options="{chunk: 5, texts:{count: '||'}}"/>
                  </div>
                </template>
              </template>
            </card>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, IonSpinner, alertController, loadingController, toastController} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import useMarketplace from "./useMarketplace";
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"


export default defineComponent({
  components: {
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSpinner,
    Pagination,
  },
  setup() {

    const router  = useRouter()
    const store   = useStore()
    const { updateTracker }     = useDataChangeTracker()
    const leadStatuses = store.state.general_data.leads_statuses

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let leads     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/leads/list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getLeads())

    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })

    const deleteRecords = async ( _records, record_type ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in _records){
        ids.push( _records[x].id)
      }

      let iAlert = await alertController.create({
        message: `Are you sure to delete the ${_records.length} selected ${record_type+(_records.length > 1 ? 's' : '')}?`,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/leads/delete', {ids: JSON.stringify(ids)})
                  .then( resp => {
                    toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if(resp.data.success){
                      // Remove the deleted entries from the advisors array
                      for(let x in ids){
                        for(let y in leads.value){
                          if(leads.value[y].id === ids[x]){
                            leads.value.splice(y,1)
                            break;
                          }
                        }
                      }
                      // All deleted, no entry left in the advisor array? and previous pages exists
                      if( !leads.value.length && pagination.page > 1 ){
                        // Deduct from total records value
                        pagination.totalRecords -= ids.length
                        // Goto previous page
                        pagination.page--
                        getLeads(true)
                      }
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }

    const { addToMarketplace } = useMarketplace()
    const _addToMarketplace = ( _leads ) => {

      let ids = []
      for(let x in _leads){
        ids.push( _leads[x].id)
      }
      addToMarketplace(_leads, (success ) => {
        if(success){
          updateTracker('admin', ['marketplace'])
          // Remove the selected leads from the leads array
          for(let x in ids){
            for(let y in leads.value){
              if(leads.value[y].id === ids[x]){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= ids.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedRecords.push( leads.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await deleteRecords(selectedRecords, 'lead')
      }
      else if( bulkSelectedAction.value === 'to_marketplace'){
        _addToMarketplace( selectedRecords )
      }
    }

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.admin.leads_list, (n) => {
      if(n){
        updateTracker('admin', ['leads_list'], false) // reset tracker
        getLeads(true)
      }
    })

    return {

      leadStatuses,
      leads,
      getLeads,
      loadingData,
      router,


      searchAdvisors,

      mandateFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,
      deleteRecords,
      _addToMarketplace,
    }
  }
});

</script>
